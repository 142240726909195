// src/eventHub.js
import mitt from 'mitt';

const eventHub = mitt(); // Create an event emitter instance

// Add alias to work with old code
eventHub.$on = eventHub.on;
eventHub.$emit = eventHub.emit;
window.eventHub = eventHub;
export default eventHub; // Export it for use across your app
